// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'
import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

import '../styles/pages/team.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const pageData = {
  pageTitle: 'Team | Learning Wings Education System',
  nakedPageSlug: 'team',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
    ajayBhatia: file(relativePath: { eq: "team/ajay-bhatia.jpg" }) {
      ...max900
    }
    deepaDogra: file(relativePath: { eq: "team/deepa-dogra.jpg" }) {
      ...max900
    }
    docbrij: file(relativePath: { eq: "team/docbrij.jpg" }) {
      ...max900
    }
    geetaMahajan: file(relativePath: { eq: "team/geeta-mahajan.jpg" }) {
      ...max900
    }
    monicaJouhal: file(relativePath: { eq: "team/monica-jouhal.jpg" }) {
      ...max900
    }
    smitaVerma: file(relativePath: { eq: "team/smita-verma.jpg" }) {
      ...max900
    }
    sriM: file(relativePath: { eq: "team/sri-m.jpg" }) {
      ...max900
    }
    jkKohli: file(relativePath: { eq: "team/jkKohli.jpg" }) {
      ...max900
    }
    meenuHuria: file(relativePath: { eq: "team/meenuHuria.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className="page team-page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Team">
        Team
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          This is the core team of Learning Wings. This core team oversees
          day-to-day operations of the school and ensure the highest quality in
          education. Each team person has a unique strength. We've built this
          great team over many years and are very proud of each and every team
          member –
        </p>
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.sriM.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">Sri M</h2>
            <p>
              His transformational journey from a young boy to a living yogi, is
              a fascination story symbolized by single-minded discipline and
              dedication. The Satsang Foundation – established by Sri M - is a
              meeting point for spiritual seekers of all persuasions. The key
              objectives and activities of the Foundation derive from the dual
              objectives of concern for mankind and the search for truth. Under
              his guidance Learning Wings balances.
            </p>
          </Fragment>
        </Division>
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.ajayBhatia.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">Ajay Bhatia</h2>
            <p>
              Being a visionary, most of the time he wears a hat of challenges.
              Puts self and team into new challenges to bring the best practices
              as well as resources to make teaching-learning relevant for future
              generation. His greatest strength being risk taking to achieve
              higher levels of excellence, has in last twelve years made
              possible for Learning Wings to reach to the heights of being one
              of best educational service provider of the region. With about
              thirteen Senior Secondary Schools, nearly Ten Thousand Students
              and Eight Hundred and Fifty Teachers, Learning Wings is on a
              mission under his leadership to education for the future ready
              learners and empowered teachers.
            </p>
          </Fragment>
        </Division>
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.deepaDogra.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">Deepa Dogra</h2>
            <p>
              Deepa Dogra is responsible for Academic Excellence of Learning
              Wings. Her roles include staff training for Teaching-learning
              standards as per Learning Wings policies. She Coordinates with
              other Academic Departments in sharing faculty and other
              educational and instructional ideas. Her major role is to develop
              educational process and policies to meet curriculum goals as per
              national and international boards. Keeping up with Learning Wings
              objectives, she endeavours to develop and implement academic
              enhancement programs for students and teachers.
            </p>
          </Fragment>
        </Division>
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.docbrij.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">Dr. Brijesh Kumar</h2>
            <p>
              Higher intellect level, a genuine human and definitely a divine
              soul makes him the driving force of our organization. He believes
              in blending modern technology with spiritual wisdom to make living
              blissful in the present challenging times. Constant up gradation
              of self in areas of education, technology and holistic living
              being the core of his mission, team is constantly on toes to
              implement and achieve the set goals. Continuous appraisals and
              feedbacks with appropriate action plans under his guidance keeps
              Learning Wings alert and alive.
            </p>
          </Fragment>
        </Division>
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.jkKohli.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">J.K. Kohli</h2>
            <p>
              Mr. J.K. Kohli is the finest blend of an Administrator and an
              Academician. With more than fifty years of experience in the field
              of education, he has dedicated his life for educating students and
              then mentoring teachers and school heads. He studied at
              Loughborough College, UK and specialized in Mathematics. Mr. Kohli
              has been associated with the top schools in India. His tenure at
              Lawrence School, Sanawar or his administrative contribution to
              Apeejay School, Jalandhar have earned him accolades from all
              strata of society. His roles in Learning Wings as a guide, mentor
              and educator have been the reason of Learning Wings being one of
              the leading Education Providers in the region.
            </p>
          </Fragment>
        </Division>
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.geetaMahajan.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">Geeta Mahajan</h2>
            <p>
              A transformational leader and an able administrator – training the
              staff, pastoral care; standard set by her is beyond comparison.
              She is a fair yet compassionate professional with sound
              educational ethics. With an experience spanning close to four
              decades devoted in the academic and pastoral care, Geeta Mahajan
              has become a role model for her teachers, staff and students. She
              is also an accredited assessor for education practices and
              policies.
            </p>
          </Fragment>
        </Division>
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.meenuHuria.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">Meenu Huria</h2>
            <p>
              Meenu Huria is the only accredited PYP, IB trained head who has
              specialized in integrated curriculum development at primary
              grades. Her concept of student led conferences to develop
              leadership from the young age and taking it to great heights by
              organizing IIMUN is her strong forte as a brilliant educator. She
              is committed to outcomes of higher order student achievements in
              all areas; academic, personality and co-scholastic. She is also an
              accredited assessor for education practices and policies.
            </p>
          </Fragment>
        </Division>
       
        <Division breakpoint={768} bigGoldenAlt>
          <div className="text">
            <Img fluid={props.data.monicaJouhal.childImageSharp.fluid} />
          </div>
          <Fragment>
            <h2 className="mask-h4">Monica Jouhal</h2>
            <p>
              Monica Jouhal has for the last ten years in all her spirits of
              knowledge, profession and attitude dedicated to nurture the
              youngest members of Learning Wings family. As an early childhood
              teacher, has been challenged to create new experiences to share
              with children. Her greatest strength is that she guides children
              and not lead them, always moves in a direction that interests the
              child. She has mentored and fostered early years teachers to
              develop and educate children to be independent learners. With her
              Master's in English and Degrees in Early Years Education as well
              as in Education, Monica has created exciting curriculum plans,
              learners centered activities and parent engagement plans. Early
              Years Learners in Learning Wings 'Shine On' under her care.
            </p>
          </Fragment>
        </Division>
      </div>
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
